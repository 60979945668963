import { type FilesDropContainerForwardRefProps } from 'components/dist/molecules/FilesDropContainer';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import { useRouter } from 'next/router';
import { Fragment, useLayoutEffect, useRef, useState, useTransition } from 'react';
import { SherpaEntityType, StorageType } from 'src/backend';
import {
    PINNED_FROM_ELEMENT_DISPLAY_ORDER, QUERY_PARAM_FORM_ELEMENT_IDS, QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID, SimpleViewFolderNames
} from 'src/constants/form-element';
import { LOAN_TABS, QUERY_PARAM_FORM_ELEMENT_ID, QUERY_PARAM_PREVIEW_FORM_ELEMENT_ID } from 'src/constants/query-params';
import { QUERY_ELEMENT_DOCUMENT_PREVIEW_ID, QUERY_PARAM_VIEWER_ACTIONS_DISABLED, ShoeBoxQueryParam } from 'src/constants/shoebox';
import { QUERY_EXPAND_FILE_PREVIEW, Route } from "src/constants/ui";
import { useFormElementContext } from 'src/contexts/form-element-context';
import { useUploadFormElementContext } from 'src/contexts/upload-form-element-context/upload-form-element-context';
import { setOptimisticElementFileUploads } from 'src/slices/form-element';
import { useDispatch } from 'src/store';
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { getIconDocumentName } from 'src/utils/form-element/get-icon-document-name';
import { isFormElementLocked } from 'src/utils/form-element/is-form-element-locked';
import { toast } from 'src/utils/toast';

import { FormElementFileRowProps } from './form-element-row.types';

export const useFormElementFileRow = (props: FormElementFileRowProps) => {
    const dispatch = useDispatch();
    const fileContainerRef = useRef<FilesDropContainerForwardRefProps>(null);
    const [dropdownClickPosition, setDropdownClickPosition] = useState<{ x: number; y: number; }>({ x: 0, y: 0 });
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const titleDivReference = useRef<HTMLDivElement>(null);
    const [isTitleOverflowing, setIsTitleOverflowing] = useState<boolean>(false);
    const formElementContextState = useFormElementContext();
    const [, startTransition] = useTransition();
    const uploadElementContext = useUploadFormElementContext();
    const router = useRouter();

    const isLocked = isFormElementLocked(props.formElement, !props.isLoggedInUserALender);

    const isPinned = props.formElement.displayOrder === PINNED_FROM_ELEMENT_DISPLAY_ORDER;

    const queryFormElementId = router.query[QUERY_PARAM_PREVIEW_FORM_ELEMENT_ID] || router.query[QUERY_PARAM_FORM_ELEMENT_ID];

    const isActive =
        (queryFormElementId === props.formElement.id && router.query[QUERY_EXPAND_FILE_PREVIEW] !== 'FULL_WIDTH') ||
        (props.isMultiChecked && router.query[QUERY_EXPAND_FILE_PREVIEW] === 'FULL_WIDTH') ||
        (router.query[QUERY_PARAM_FORM_ELEMENT_IDS]?.includes(props.formElement.id)) ||
        (!queryFormElementId && !props.formElement.parentId);

    const isUploadDisabled = props.formElement.storageType === 'FOLDER' || isLocked;

    const isIndividualFormElement = props.formElement.entityType === "INDIVIDUAL";

    const isAssetFormElement = props.formElement.entityType === "ASSET";

    const isCompanyFormElement = props.formElement.entityType === "COMPANY";

    const isTrustFormElement = props.formElement.entityType === "TRUST";

    const isDeleteDisabled = !props.formElement.parentId || isLocked;

    const isRoot = !props.formElement.parentId;

    let editLabel = 'Rename';
    if (props.formElement.storageType === 'FOLDER' &&
        props.formElement.knowledgeBase === 'ENTITY_FOLDER' &&
        !!props.formElement.sherpaEntityId) {
        if (isIndividualFormElement) {
            editLabel = "Edit Individual";
        } else if (isAssetFormElement) {
            editLabel = 'Edit Asset';
        } else if (isCompanyFormElement) {
            editLabel = 'Edit Company';
        } else if (isTrustFormElement) {
            editLabel = 'Edit Trust';
        }
    }

    const onEditOneDriveFile = (platform: "WEB" | "DESKTOP" | "WEB_ANONYMOUS") => {
        uploadElementContext.onEditOneDriveFile(props.formElement, platform);
    }

    const handleOpenNewTab = () => {
        const url = new URL(window.location.href);
        url.searchParams.set(QUERY_PARAM_VIEWER_ACTIONS_DISABLED, '1');
        url.searchParams.set(QUERY_PARAM_FORM_ELEMENT_ID, props.formElement.id);
        url.searchParams.set(QUERY_ELEMENT_DOCUMENT_PREVIEW_ID, props.formElement.answer?.document?.id);
        url.searchParams.set('tab', LOAN_TABS.PACKAGE);
        url.searchParams.set(QUERY_EXPAND_FILE_PREVIEW, 'NORMAL');

        window.open(url.toString(), '_blank');
    };

    const onDeleteElementClick = () => {
        formElementContextState.onConfirmDeleteDialog(props.formElement, 'QUESTION');
    };

    const onDeleteAnswerClick = () => {
        dispatch(setOptimisticElementFileUploads({
            elementId: props.formElement.id,
            fileName: null
        }))
        formElementContextState.onConfirmDeleteDialog(props.formElement, 'ANSWER');
    };

    const onDownloadClick = async () => {
        uploadElementContext.onDownload([props.formElement]);
    };

    const handlePinClick = () => {
        formElementContextState.onPinToTopClick(props.formElement.id, isPinned);
    };

    const handleCreateFileRequestClick = (e) => {
        e.stopPropagation();
        formElementContextState.onSetEditFileRequestIds(["new"])
    };

    const handleRejectClick = () => {
        formElementContextState.onRejectFormElement(props.formElement);
    };

    const handleEditClick = async (event) => {
        if (isDropdownVisible) {
            onChangeDropDownVisibility();
        }
        event.stopPropagation();
        if (
            props.formElement.knowledgeBase === 'ENTITY_FOLDER' &&
            props.formElement.storageType === 'FOLDER' &&
            !!props.formElement.sherpaEntityId
        ) {

            if (isCompanyFormElement) {
                router.push({
                    pathname: Route.LOAN_COMPANY_EDIT,
                    query: {
                        loanId: props.formElement.loanId,
                        companyId: props.formElement.sherpaEntityId,
                        tab: LOAN_TABS.PACKAGE,
                        [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
                    },
                });
            } else if (isTrustFormElement) {
                router.push({
                    pathname: Route.LOAN_TRUST_EDIT,
                    query: {
                        loanId: props.formElement.loanId,
                        trustId: props.formElement.sherpaEntityId,
                        tab: LOAN_TABS.PACKAGE,
                        [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
                    },
                });
            } else if (isAssetFormElement) {
                router.push({
                    pathname: Route.LOAN_ASSET_EDIT,
                    query: {
                        loanId: props.formElement.loanId,
                        assetId: props.formElement.sherpaEntityId,
                        [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
                        tab: LOAN_TABS.PACKAGE
                    }
                })
            } else if (isIndividualFormElement) {
                const loanEntity = props.loan.loanEntities.find(loanEntity => loanEntity.sherpaEntity.id === props.formElement.sherpaEntityId);
                if (loanEntity.sherpaEntity.userIndividualId) {
                    const entityLoanRole = props.loan.loanRoles.find(loanRole => loanRole.user.id === loanEntity.sherpaEntity.userIndividualId);
                    if (entityLoanRole) {
                        router.push({
                            pathname: Route.EDIT_LOAN_PRINCIPAL,
                            query: {
                                loanId: props.formElement.loanId,
                                personId: entityLoanRole.id,
                                tab: LOAN_TABS.PACKAGE,
                                entityId: props.formElement.sherpaEntityId,
                                [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
                            }
                        })
                    } else {
                        toast({
                            content: 'Could not find loan role entity',
                            type: 'error',
                        })
                    }
                } else {
                    toast({
                        content: 'Could not find loan entity',
                        type: 'error',
                    })
                }
            } else {
                toast({
                    content: 'Entity type not supported',
                    type: 'error',
                });

            }
        } else {
            formElementContextState.onOpenFormElementDialog(props.formElement);
        }
    };

    const onCheckboxClick = () => {
        props.onCheckedChange?.(!props.isMultiChecked)
        formElementContextState.onMultiSelect(props.formElement);
    };

    const onToggleVisibility = () => {
        formElementContextState.onToggleVisibility(props.formElement);
    };

    const onAddCompanyClick = () => {
        router.push({
            pathname: Route.CREATE_LOAN_COMPANY,
            query: {
                ...router.query,
                [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
            },
        });
    };

    const onAddTrustClick = () => {
        router.push({
            pathname: Route.CREATE_LOAN_TRUST,
            query: {
                ...router.query,
                [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
            },
        });
    };

    const onAddCollateralClick = () => {
        router.push({
            pathname: Route.CREATE_LOAN_ASSET,
            query: {
                ...router.query,
                [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
            },
        });
    };

    const onAddIndividual = () => {
        router.push({
            pathname: Route.CREATE_LOAN_APPLICANT,
            query: {
                ...router.query,
                [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
            },
        });
    };

    const handleContextMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        // if an element is selected for multi select, then we don't want to show the context menu
        if (formElementContextState.multiSelect.formElements.length > 0) {
            return;
        }
        // get context menu right click position
        const x = event.clientX;
        const y = event.clientY;
        // find element with data-id="dropdown-trigger" and get it's left and top
        const element = event.currentTarget.querySelector('[data-id="dropdown-trigger"]');
        if (!element) {
            return;
        }
        const left = element.getBoundingClientRect().left;
        const top = element.getBoundingClientRect().top;

        startTransition(() => {
            setDropdownClickPosition({
                x: x,
                y: y,
            });
            setIsDropdownVisible(true);
        });
    };

    const onChangeDropDownVisibility = () => {
        startTransition(() => {
            setIsDropdownVisible(prevState => {
                // if it's already visible, then we should close it
                if (prevState) {
                    return false;
                } else {
                    return true;
                }
            });
        });
    }

    const onDropdownTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
        // get target top and left
        const left = event.currentTarget.getBoundingClientRect().left + window.scrollX;
        const top = event.currentTarget.getBoundingClientRect().top + window.scrollY;
        startTransition(() => {
            setDropdownClickPosition({
                x: left,
                y: top,
            });
            setIsDropdownVisible(prevState => !prevState);
        });
    }

    const onEditDetailsClick = () => {
        setIsDropdownVisible(false);
        formElementContextState.onSetEditFileRequestIds([props.formElement.id]);
    };

    const onMouseEnter = () => {
        setIsMouseOver(true);
    }

    const onFocus = () => {
        setIsMouseOver(true);
    }

    const onDoubleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        // if we are in FULL_WIDTH mode
        // and this an answered file
        // we should open the full screen preview
        if ((router.query[QUERY_EXPAND_FILE_PREVIEW] === 'FULL_WIDTH' || props.isSmallScreen) &&
            props.formElement.answer &&
            props.formElement.storageType === 'FILE') {
            router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
                    [QUERY_ELEMENT_DOCUMENT_PREVIEW_ID]: props.formElement.answer?.document?.id,
                    [QUERY_PARAM_VIEWER_ACTIONS_DISABLED]: 1
                }
            })
        } else if (props.isSmallScreen) {
            router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
                    [QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID]: props.formElement.id,
                    [QUERY_ELEMENT_DOCUMENT_PREVIEW_ID]: props.formElement.answer?.document?.id,
                    [QUERY_PARAM_VIEWER_ACTIONS_DISABLED]: 1
                }
            })
        }
    }

    const onRowClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        // if is a file request
        // we should check this file request
        if (props.formElement.storageType === 'FILE') {
            if (event.shiftKey) {
                props.onCheckElementsFromOrToCurrent(props.formElement)
            } else if (event.ctrlKey || event.metaKey) {
                onCheckboxClick();
            } else if (props.isSmallScreen) {
                onDoubleClick(event)
            } else if (router.query[QUERY_EXPAND_FILE_PREVIEW] === 'FULL_WIDTH') {
                formElementContextState.onSetMultiSelect([props.formElement]);
            } else {
                router.push({
                    pathname: router.pathname,
                    query: {
                        ...router.query,
                        ...(!props.isLocationVisible) ? {
                            [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
                        } : {
                            [QUERY_PARAM_PREVIEW_FORM_ELEMENT_ID]: props.formElement.id,
                        },
                        [QUERY_ELEMENT_DOCUMENT_PREVIEW_ID]: undefined,
                        [ShoeBoxQueryParam.documentId]: undefined,
                        [ShoeBoxQueryParam.documentName]: undefined,
                        [ShoeBoxQueryParam.providerType]: undefined,
                        [QUERY_PARAM_VIEWER_ACTIONS_DISABLED]: undefined
                    }
                })
            }
        } else if (props.formElement.storageType === 'FOLDER') {
            router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    [QUERY_PARAM_FORM_ELEMENT_ID]: props.formElement.id,
                    [QUERY_ELEMENT_DOCUMENT_PREVIEW_ID]: undefined,
                    [QUERY_PARAM_VIEWER_ACTIONS_DISABLED]: undefined,
                    [ShoeBoxQueryParam.documentId]: undefined,
                    [ShoeBoxQueryParam.documentName]: undefined,
                    [ShoeBoxQueryParam.providerType]: undefined,
                }
            })
        }
    }

    const onCopyToClick = () => {
        uploadElementContext.onCopyMove({ operation: "COPY", type: "ELEMENTS", elements: [props.formElement], loanId: props.formElement.loanId });
    }

    const onMoveToClick = () => {
        uploadElementContext.onCopyMove({ operation: "MOVE", type: "ELEMENTS", elements: [props.formElement], loanId: props.formElement.loanId });
    }

    const onAssignClick = () => {
        formElementContextState.onSetAssignElements([props.formElement]);
    }

    const onDropFiles = (droppedFiles: File[]) => {
        uploadElementContext.onDropFiles({
            targetElement: props.formElement,
            droppedFiles,
        })
    };

    const onSendMessage = (element: FormElementV2ResponseDtoExtended) => {
        if (isDropdownVisible) {
            onChangeDropDownVisibility();
        }
        props.onSendMessage(props.formElement);
    }
    const onUploadClick = () => {
        if (fileContainerRef.current) {
            fileContainerRef.current.openFilePicker();
        }
    }
    // this is used as a data type for the qa automation
    // default is storage type
    let qaDataType: SherpaEntityType | StorageType = props.formElement.storageType
    // if it's a folder and it has a sherpa entity type, then we use that
    if (props.formElement.sherpaEntityType && props.formElement.storageType === 'FOLDER') {
        qaDataType = props.formElement.sherpaEntityType
    }

    const isExpandedMode = router.query[QUERY_EXPAND_FILE_PREVIEW] === 'FULL_WIDTH';

    useLayoutEffect(() => {
        if (!titleDivReference.current) {
            return;
        }
        setIsTitleOverflowing(false);
        const titleDivReferenceCurrent = titleDivReference.current
        const setOverflowing = () => {
            const isTitleOverflowing = titleDivReferenceCurrent.scrollWidth > titleDivReferenceCurrent.clientWidth;
            if (isTitleOverflowing) {
                setIsTitleOverflowing(true)
            }
        }
        try {
            // use ResizeObserver to check if the title is overflowing
            const resizeObserver = new ResizeObserver(setOverflowing);
            resizeObserver.observe(titleDivReferenceCurrent);
            return () => {
                resizeObserver.disconnect();
            }
        } catch (error) {
            // if ResizeObserver is not supported, then just check if the title is overflowing
            setOverflowing();
        }
    }, [props.formElement.title, isExpandedMode]);

    // delete answer is allowed for:
    // lending team
    // borrowing team if element is not accepted yet
    const isDeleteAnswerAllowed = props.formElement.answer && !isLocked;

    return {
        documentName: getIconDocumentName({
            documentName: props.formElement.answer?.document?.name,
            modifiers: props.formElement.modifiers
        }),
        isDeleteAnswerAllowed,
        isDropdownVisible,
        isActive,
        isPinned,
        uploadProgress: 0,
        isLastModifiedByUserVisible: (props.isLoggedInUserALender || (!props.isLoggedInUserALender && ['CONTACT', 'BORROWER'].includes(props.formElement.lastModifiedByUser?.loggedRoleGroup))),
        isDeleteDisabled,
        isUploadDisabled,
        isRoot,
        editLabel,
        router,
        isLocked,
        qaDataType,
        titleDivReference,
        isLender: props.isLoggedInUserALender,
        isTitleOverflowing,
        packageColumnStatus: router.query[QUERY_EXPAND_FILE_PREVIEW] ?? 'FULL_WIDTH',
        dropdownClickPosition,
        DropdownPortal: !props.isNeedsListModal ? ScreenSizeDropdownMenu.Portal : Fragment,
        loggedInUserId: props.loggedInUserId,
        loggedInUserRole: props.loggedInUserRole,
        isUserALender: props.isLoggedInUserALender,
        isMouseOver,
        fileContainerRef,
        onUploadClick,
        onAssignClick,
        onEditOneDriveFile,
        onCheckElementsFromOrToCurrent: props.onCheckElementsFromOrToCurrent,
        onCheckboxClick,
        onDoubleClick,
        onRowClick,
        onPinClick: handlePinClick,
        onMoveToClick,
        onCopyToClick,
        onDeleteElementClick,
        onDeleteAnswerClick,
        onDownloadClick,
        onToggleVisibility,
        onEditDetailsClick,
        onChangeDropDownVisibility,
        onOpenNewTabClick: handleOpenNewTab,
        onEditClick: handleEditClick,
        onSendMessage,
        onRejectClick: handleRejectClick,
        onCreateFileRequestClick: handleCreateFileRequestClick,
        onAddCompanyClick,
        onAddTrustClick,
        onAddCollateralClick,
        onAddIndividual,
        onDropdownTriggerClick,
        onDropFiles,
        onContextMenuClick: handleContextMenuClick,
        onMouseEnter,
        onFocus,
    } as const;
};


